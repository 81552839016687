<template>
  <b-modal
    :visible="showModalAcessorio"
    title="Nota fiscal do acessório"
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <div v-if="loadingArquivos" class="text-center">
      <h5>Carregando...</h5>
    </div>

    <div v-if="!loadingArquivos && arquivoUrl">
      <img
        v-if="verificaTipoImagem(arquivoUrl)"
        :src="arquivoUrl"
        style="width:200px"
      />
      <img
        v-else-if="verificaTipoPdf(arquivoUrl)"
        src="img/icone-pdf.svg"
        style="width:70px"
      />
      <img
        v-else
        src="img/no-image.png"
        style="width:80px"
        alt="Sem imagem disponível"
      />
      <br />
      <a :href="arquivoUrl" target="_blank">Abrir nota fiscal</a>
    </div>
    <div class="mt-3">
      <strong>Anexar nota fiscal </strong>
      <hr class="mt-0" />
      <b-form-file
        v-model="arquivosUpload"
        placeholder="Escolha um arquivo..."
        drop-placeholder="Arraste um arquivo..."
        browse-text="Escolher"
        multiple
        :accept="config.UPLOAD_FILE_TYPES"
      ></b-form-file>

      <button
        class="btn btn-sm btn-success btn-block mt-5"
        @click="addArquivo"
        :disabled="loadingAddArquivo"
      >
        <span v-if="!loadingAddArquivo">Anexar</span>
        <span v-if="loadingAddArquivo">
          <small class="text-purple">Anexando...</small>
        </span>
      </button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import config from "../../config";
import Auth from "../../auth";
import captureError from "../../helpers/captureError";
import helper from "../../helpers/helper";
export default {
  props: ["showModalAcessorio", "idSeguro"],

  data() {
    return {
      Auth: Auth,
      config: config,

      loadingAddArquivo: false,
      loadingArquivos: false,

      tipoArquivo: "",
      arquivosUpload: [],

      arquivoUrl: "",
      modificou: false,
    };
  },

  methods: {
    hideModal(arg, data) {
      this.$emit("hideAcessorio", data || { modificou: false });
      //Limpa os dados ao fechar a modal
      this.tipoArquivo = {};
      this.arquivo = "";
    },
    async addArquivo() {
      try {
        if (!this.arquivosUpload || this.arquivosUpload.length == 0) {
          return;
        }

        this.loadingAddArquivo = true;

        var data = new FormData();
        data.set("IdSeguro", this.idSeguro);

        for (let index = 0; index < this.arquivosUpload.length; index++) {
          const element = this.arquivosUpload[index];
          data.append("Arquivo", element);

          //Verificando o tipo do arquivo
          var extensaoArquivo = helper
            .obterExtensaoArquivo(element.name)
            .toLowerCase();
          if (
            config.UPLOAD_FILE_TYPES.toLowerCase().indexOf(extensaoArquivo) ==
            -1
          ) {
            this.loadingAddArquivo = false;

            Swal.fire({
              type: "warning",
              title: "Tipo de arquivo inválido",
              html: `<small>Arquivo: ${element.name}</small>`,
            });

            return;
          }

          //Verificando tamanho dos arquivos
          var fileSize = element.size / 1024 / 1024;

          if (fileSize > config.MAX_UPLOAD_FILE_SIZE_MB) {
            this.loadingAddArquivo = false;

            Swal.fire({
              type: "warning",
              title: "Arquivo muito grande",
              html: `O tamanho do arquivo não pode passar de ${
                config.MAX_UPLOAD_FILE_SIZE_MB
              }MB. <br/> <small>Arquivo: ${element.name} (${fileSize.toFixed(
                0
              )}MB)</small>`,
            });

            return;
          }
        }

        //todo: bloquear pelo id do adm

        await axios.post(`${config.API_URLV2}/seguro/anexarAcessorio`, data);
        Swal.fire("Sua nota fiscal foi enviada com sucesso!!", "", "success");

        this.arquivosUpload = [];
        this.tipoArquivo = "";
        this.loadingAddArquivo = false;

        this.hideModal(undefined, { modificou: true });
      } catch (e) {
        this.loadingAddArquivo = false;

        if (e.response && e.response.data && e.response.data.invalido) {
          Swal.fire({
            icon: "error",
            title: "Tipo de arquivo iválido",
          });
          return;
        }

        captureError(e);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível anexar o arquivo. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    verificaTipoImagem(url) {
      if (!url) {
        return false;
      }

      var imageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      return imageExtensions.exec(url);
    },

    verificaTipoPdf(url) {
      if (!url) {
        return false;
      }
      return url.endsWith(".pdf");
    },
  },
};
</script>
<style scoped>
.nome-arquivo {
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}

.img-container {
  position: relative;
}

.img-item {
  width: 100%;
  max-width: 100%;
  padding: 4px;
}

.btn-delete {
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  padding: 0px 5px;
  display: none;
}

.btn-delete:hover {
  background: #dc3545;
}
.img-container:hover .btn-delete {
  display: block;
}
</style>
